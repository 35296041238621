<template>
	<div>
		<div class="top-nav-bar">
			<TopNavBar />
		</div>
		<div class="order-content">
			<div class="list-content" v-for="(item, i) in list" :key="i">
				<div class="list" :class="i > 0 ? 'margin-top-xs' : ''">
					<div class="flex-btw">
						<div class="flex-center"><img class="doc" src="~@/assets/img-v3/new-order/table.png" />
						</div>
						<div class="flex">
							<div class="flex-center ">
								<div class="order-time">{{ timestampToTime(item.created) }}</div>
							</div>
							<div class="flex-center" style="margin: 0 10px;">
								<div class="btn" style="background: #0078FF;" @click="exportOrders(item)">保存</div>
							</div>
							<div class="flex-center">
								<div class="btn" @click="copyUrl(item)">分享</div>
							</div>
						</div>

					</div>
				</div>
			</div>
		</div>
		<!-- 水印 -->
		<WaterMark />
	</div>
</template>

<script>
	import TopNavBar from './components/TopNavBar.vue';
	import {
		Toast
	} from 'vant';
	import {
		getOrder,
		exportOrder
	} from '@/api';
	import {
		timestampToTime
	} from '@/utils';

	export default {
		data() {
			return {
				checked: true,
				value: 0,
				list: [],
				message: ''
			};
		},
		components: {
			TopNavBar
		},
		mounted() {
			this.getOrder();
		},
		computed: {
			baseUrl() {
				return this.$store.state.baseUrl;
			}
		},
		methods: {
			//数据处理
			handleData(item) {
				let url = exportOrder();
				this.message = this.baseUrl + url + item.id;
			},
			copyUrl(item) {
				this.handleData(item);
				this.$copyText(this.message).then(
					function(e) {
						Toast.success('下载链接已经复制');
					},
					function(e) {
						Toast.success('复制失败');
					}
				);
			},
			exportOrders(item) {
				let url = exportOrder();
				const downUrl = this.baseUrl + url + item.id;
				let a = document.createElement('a');
				a.href = downUrl;
				a.click();
			},
			timestampToTime(timestamp) {
				return timestampToTime(timestamp);
			},
			getOrder() {
				this.loading = this.showList;
				let userId = this.$store.state.userInfo?this.$store.state.userInfo.uuid :''
				const params = {
					page: 1,
					pageSize: 10000,
					shopUuid: this.$store.state.shopUuid,
					userId: userId
				};
				getOrder(params).then(res => {
					if (res.state == 100) {
						this.list = res.items.list;
					}
				});
			}
		}
	};
</script>

<style lang="less" scoped="scoped">
	.order-content {
		padding-top: 68px;
		padding-bottom: 20px;

		.margin-top-xs {
			margin-top: 5px;
		}

		.list-content {
			display: flex;
			justify-content: center;
			height: 75px;

			.list {
				width: 100%;
				border-radius: 5px;
				background-color: #fafafa;
				padding: 12px;
				display: flex;
				margin: 8px;
				height: 60px;

				.doc {
					width: 33px;
					height: 36px;
				}

				.order-time {
					width: 100px;
					height: 25px;
					background: #C365F9;
					border-radius: 13px;
					color: #ffffff;
					display: flex;
					justify-content: center;
					align-items: center;
					text-align: center;
				}

				;

				img {
					width: 65px;
					height: 46px;
				}
			}
		}

		.flex-center {
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.flex-btw {
			display: flex;
			width: 100%;
			justify-content: space-between;
		}
	}

	.top-nav-bar {
		position: fixed;
		top: 0;
		width: 100%;
		height: 60px;
		z-index: 999;
	}

	.van-checkbox__label {
		width: 100%;
	}

	.btn {
		width: 60px;
		height: 25px;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		color: #ffffff;
		background: #2de1a5;
		border-radius: 15px;
	}
</style>
